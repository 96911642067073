import React from "react";
import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import MainMenu from "../../components/sidemenu/MainMenu";

const TermsAndCondition = () => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden bg-gray-50">
            {/* Sidebar */}
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F] text-white">
                <MainMenu />
            </div>

            {/* Main Content */}
            <div className="col-span-11 overflow-y-scroll p-2">
                {/* Breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#828282] cursor-pointer" onClick={() => navigate("/settings")}>Settings</p>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Terms And Conditions</p>
                </div>

                {/* Page Title */}
                <div className="mt-5">
                    <h1 className="text-3xl font-bold text-gray-800 mb-4">
                        Terms and Conditions
                    </h1>

                    {/* Main Terms Content */}
                    <div className="space-y-6 text-gray-700 leading-6">
                        <section>
                            <h2 className="text-xl font-semibold text-gray-800 mb-2">
                                1. Acceptance of Terms
                            </h2>
                            <p>
                                By accessing and using this application, you agree to comply with
                                and be bound by the following terms and conditions. If you do not
                                agree with any part of these terms, you must not use this service.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-xl font-semibold text-gray-800 mb-2">
                                2. Changes to Terms
                            </h2>
                            <p>
                                We reserve the right to update these terms at any time. Updates
                                will be effective immediately upon posting. You are encouraged to
                                review the terms periodically to stay informed.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-xl font-semibold text-gray-800 mb-2">
                                3. User Responsibilities
                            </h2>
                            <ul className="list-disc pl-6 space-y-2">
                                <li>Maintain the confidentiality of your account details.</li>
                                <li>Refrain from unlawful or prohibited usage of the platform.</li>
                                <li>
                                    Notify us immediately of any unauthorized use of your account.
                                </li>
                            </ul>
                        </section>

                        <section>
                            <h2 className="text-xl font-semibold text-gray-800 mb-2">
                                4. Limitation of Liability
                            </h2>
                            <p>
                                Our service is provided "as is" without warranties of any kind. We
                                are not liable for any direct, indirect, incidental, or
                                consequential damages resulting from the use of our platform.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-xl font-semibold text-gray-800 mb-2">
                                5. Governing Law
                            </h2>
                            <p>
                                These terms shall be governed and construed in accordance with the
                                laws of [Your Country/Region]. Disputes will be resolved under the
                                jurisdiction of [Your Courts].
                            </p>
                        </section>
                    </div>

                    {/* Footer */}
                    <div className="mt-10 border-t pt-6 text-gray-500 text-sm">
                        <p>&copy; 2024 Your Company Name. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsAndCondition;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainMenu from "../../components/sidemenu/MainMenu";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

const Login = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                <MainMenu />
            </div>
            <div className="col-span-11 pt-[2rem]">
                <p className="flex justify-center text-[22px] font-bold">
                    Kind Viewer Ad Share Log - In
                </p>
                <div className="flex justify-center pt-[1rem]">
                    <div className="w-[30rem] border border-gray-400 bg-[#f4f4f4] rounded-xl p-5 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
                        <div className="flex flex-col gap-y-3">
                            <div className="grid grid-cols-12 gap-4 items-center">
                                <div className="col-span-3">
                                    <p className="text-[16px]">
                                        Email:
                                    </p>
                                </div>
                                <div className="col-span-9">
                                    <input
                                        type="text"
                                        placeholder="Enter your email address"
                                        className="w-full px-2 py-2 border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none"
                                    />
                                </div>
                                <div className="col-span-3">
                                    <p className="text-[16px]">
                                        Password:
                                    </p>
                                </div>
                                <div className="col-span-9 relative">
                                    <input
                                        placeholder="Enter your password"
                                        type={showPassword ? "text" : "password"}
                                        className="w-full pl-2 pr-10 py-2 border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none"
                                    />
                                    <div
                                        className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <IoEyeOffOutline className="text-[20px]" /> : <IoEyeOutline className="text-[20px]" />}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center pt-1 pb-5">
                                <div className="flex items-center gap-x-2">
                                    <input
                                        type="checkbox"
                                        className="h-4 w-4 cursor-pointer accent-[#00AB07]"
                                    />
                                    <p className="text-[14px]">
                                        Keep me signed in
                                    </p>
                                </div>
                                <p
                                    onClick={() => navigate("/forgot-pass")} 
                                    className="text-[14px] text-[#00C2FF] cursor-pointer hover:underline"
                                >
                                    Forgot Password
                                </p>
                            </div>
                            <div className="flex justify-between items-center">
                                <button
                                    onClick={() => navigate("/signup")}
                                    className="w-[8rem] py-2 text-[14px] bg-[#00AB07] hover:bg-opacity-90 text-[#ffffff] rounded"
                                >
                                    Signup
                                </button>
                                <button
                                    onClick={() => navigate("/home")}
                                    className="w-[8rem] py-2 text-[14px] bg-[#000000] hover:bg-opacity-90 text-[#ffffff] rounded"
                                >
                                    Log In
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
import React, { useState } from "react";
import { FaComment } from "react-icons/fa";
import cent_bidding from "../assets/sidemenuimg/1_cent_bidding.png";
import your_adverts from "../assets/sidemenuimg/your_adverts.png";
import tier_system from "../assets/sidemenuimg/tier_system.png";
import team_up from "../assets/sidemenuimg/team_up.png";
import quick_create from "../assets/sidemenuimg/quick_create.png";
import performance from "../assets/sidemenuimg/performance.png";
import financials from "../assets/sidemenuimg/financials.png";
import objectives from "../assets/sidemenuimg/objectives.png";

const HelpMode = () => {
    const [activeItemId, setActiveItemId] = useState(1);

    const menuItem = [
        { id: 1, name: "1 - Cent Bidding", icon: cent_bidding, content: "1 - Cent Bidding" },
        { id: 2, name: "Total users reached", number: "123000", content: "Total users reached" },
        { id: 3, name: "Your Adverts", icon: your_adverts, content: "Your Adverts" },
        { id: 4, name: "Tier System", icon: tier_system, content: "Tier System" },
        { id: 5, name: "Team - Up", icon: team_up, content: "Team - Up" },
        { id: 6, name: "Quick Create", icon: quick_create, content: "Quick Create" },
        { id: 7, name: "Performance", icon: performance, content: "Performance" },
        { id: 8, name: "Financials", icon: financials, content: "Financials" },
        { id: 9, name: "Objectives", icon: objectives, content: "Objectives" }
    ];

    const handleNext = () => {
        setActiveItemId((prevId) => (prevId < menuItem.length ? prevId + 1 : menuItem.length));
    };

    const handlePrev = () => {
        setActiveItemId((prevId) => (prevId > 1 ? prevId - 1 : 1));
    };

    return (
        <div className="relative">
            <div className="fixed bg-[#000000] opacity-70 inset-0" />

            <div className="grid grid-cols-12 gap-x-2 ">
                <div className="col-span-4 overflow-y-scroll">
                    <div className="grid grid-cols-12">
                        <div className={`col-span-4 px-3 bg-[#640D5F] pt-5 w-[73%]`}>
                            {menuItem.map((item) => (
                                <div
                                    key={item.id}
                                    className={`h-24 w-24 p-5 flex flex-col items-center justify-center rounded-full bg-white mb-3 cursor-pointer  ${item.id === activeItemId ? "relative z-20" : ""}`}
                                >
                                    {item.icon ? (
                                        <img
                                            src={item.icon}
                                            alt={item.name}
                                            className="h-10 w-10 mb-1"
                                        />
                                    ) : (
                                        <p className="text-lg font-bold">{item.number}</p>
                                    )}
                                    <p className="text-xs text-black font-semibold leading-tight text-center">
                                        {item.name}
                                    </p>
                                </div>
                            ))}
                        </div>

                        <div className={`col-span-8 pt-5 ml-[-16px]`}>
                            {menuItem.map((item) => (
                                <div className="flex relative">
                                    {item.id === activeItemId &&
                                        <FaComment
                                            className={`h-[6rem] w-[7rem] mt-[1px] ml-[-1.82rem] rotate-[45deg] text-[#ffffff] 
                                            absolute top-1/2 left-[1rem] transform -translate-y-1/2 z-10`}
                                        />
                                    }

                                    <div
                                        key={item.id}
                                        className={`flex justify-center items-center ml-[0rem] h-24 w-full p-5 
                                            rounded-full bg-[#ffffff] mb-3 cursor-pointer 
                                            ${item.id === activeItemId ? "relative z-10" : "-z-20"}`
                                        }
                                    >
                                        <p
                                            className={`text-[14px] text-center 
                                            ${item.id === activeItemId ? "text-black" : "text-white"}`
                                            }
                                        >
                                            {item.name}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="col-span-8">
                    <div className="fixed top-[50%] left-[50%]">
                        <div className="flex items-center gap-x-2 justify-center">
                            <button
                                onClick={handlePrev}
                                disabled={activeItemId === 1}
                                className={`text-[14px] px-5 py-2 rounded-full z-20  
                                    ${activeItemId === 1 ? "bg-[#676262] text-[#908a8a]" : "bg-[#000000] text-white"}`
                                }
                            >
                                Previous
                            </button>
                            <button
                                onClick={handleNext}
                                disabled={activeItemId === menuItem.length}
                                className={`text-[14px] px-5 py-2 rounded-full z-20  
                                    ${activeItemId === menuItem.length ? "bg-[#6c6666] text-[#908a8a]" : "bg-[#000000] text-white"}`
                                }
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HelpMode;
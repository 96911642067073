import React, { Fragment } from "react";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import ForgotPass from "./pages/auth/ForgotPass";
import NotFound from "./pages/NotFound";
import Header from "./components/Header";
import Home from "./pages/Home";
import Support from "./components/Support";
import HowItWork from "./pages/HowItWork";
import YourKeywords from "./pages/YourKeywords";
import WorkSteps from "./pages/howitwork/WorkSteps";
import TeamUp from "./pages/TeamUp";
import TeamUpSearch from "./pages/teamup/TeamUpSearch";
import CurrentTeampUp from "./pages/teamup/CurrentTeampUp";
import YourTeamUpProfile from "./pages/teamup/YourTeamUpProfile";
import CurrentTeamUpDetails from "./pages/teamup/CurrentTeamUpDetails";
import PopularTeamUp from "./pages/teamup/PopularTeamUp";
import ApplicationStatus from "./pages/teamup/ApplicationStatus";
import ApplicationDetails from "./pages/teamup/ApplicationDetails";
import ApplicationSubmit from "./pages/teamup/ApplicationSubmit";
import TeamUpApplication from "./pages/teamup/TeamUpApplication";
import TeampUpMessage from "./pages/teamup/TeampUpMessage";
import TierSystem from "./pages/TierSystem";
import SimilarAdvertisers from "./pages/tiersystem/SimilarAdvertisers";
import HowTierSystemWork from "./pages/tiersystem/HowTierSystemWork";
import HigherTierAdvertiser from "./pages/tiersystem/HigherTierAdvertiser";
import TierIncreaseApplication from "./pages/tiersystem/TierIncreaseApplication";
import OneCentBidding from "./pages/OneCentBidding";
import OneCentBiddingDetails from "./pages/onecentbid/OneCentBiddingDetails";
import PreviousBid from "./pages/onecentbid/PreviousBid";
import HowOneCentBidWork from "./pages/onecentbid/HowOneCentBidWork";
import YourAdvert from "./pages/YourAdvert";
import DraftedAdvert from "./pages/youradvert/DraftedAdvert";
import InactiveAdvert from "./pages/youradvert/InactiveAdvert";
import InactiveAdvertSucess from "./pages/youradvert/InactiveAdvertSucess";
import BidAndPostView from "./pages/youradvert/BidAndPostView";
import QuickCreate from "./pages/youradvert/QuickCreate";
import CreateAdvert from "./pages/youradvert/CreateAdvert";
import Objectives from "./pages/Objectives";
import AddObjectives from "./pages/objectives/AddObjectives";
import YourTimeline from "./pages/objectives/YourTimeline";
import Performance from "./pages/Performance";
import Financials from "./pages/Financials";
import MonthlyData from "./pages/financial/MonthlyData";
import Invoice from "./pages/financial/Invoice";
import Settings from "./pages/Settings";
import ContactUs from "./pages/ContactUs";
import DeleteAccount from "./pages/DeleteAccount";
import HelpMode from "./pages/HelpMode";
import Account from "./pages/Account";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import TermsAndCondition from "./pages/legal/TermsAndCondition";
import CookiesPolicy from "./pages/legal/CookiesPolicy";
import EditAccount from "./pages/account/EditAccount";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
    return (
        <Fragment>
            <p className="block xl:hidden text-lg font-semibold text-center">
                Please open this application on a laptop or desktop for the best experience.
            </p>
            <div className="hidden xl:block">
                <Router>
                    <Header />
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/forgot-pass" element={<ForgotPass />} />

                        <Route path="/home" element={<Home />} />

                        <Route path="/account" element={<Account />} />
                        <Route path="/account/edit" element={<EditAccount />} />

                        <Route path="/how" element={<HowItWork />} />
                        <Route path="/how/steps" element={<WorkSteps />} />

                        <Route path="/team-up" element={<TeamUp />} />
                        <Route path="/team-up/search" element={<TeamUpSearch />} />
                        <Route path="/team-up/current" element={<CurrentTeampUp />} />
                        <Route path="/team-up/current/:id" element={<CurrentTeamUpDetails />} />
                        <Route path="/team-up/popular" element={<PopularTeamUp />} />
                        <Route path="/team-up/application" element={<ApplicationStatus />} />
                        <Route path="/team-up/application/view/:id" element={<ApplicationDetails />} />
                        <Route path="/team-up/application/:id" element={<TeamUpApplication />} />
                        <Route path="/team-up/application/submit" element={<ApplicationSubmit />} />
                        <Route path="/team-up/message" element={<TeampUpMessage />} />
                        <Route path="/team-up/profile" element={<YourTeamUpProfile />} />

                        <Route path="/tier-system" element={<TierSystem />} />
                        <Route path="/tier-system/similar-advertiser" element={<SimilarAdvertisers />} />
                        <Route path="/tier-system/how-it-work" element={<HowTierSystemWork />} />
                        <Route path="/tier-system/higher-advertiser" element={<HigherTierAdvertiser />} />
                        <Route path="/tier-system/increase-application" element={<TierIncreaseApplication />} />

                        <Route path="/one-cent" element={<OneCentBidding />} />
                        <Route path="/one-cent/details/:id" element={<OneCentBiddingDetails />} />
                        <Route path="/one-cent/previous-bid" element={<PreviousBid />} />
                        <Route path="/one-cent/how-bid-work" element={<HowOneCentBidWork />} />

                        <Route path="/your-advert" element={<YourAdvert />} />
                        <Route path="/your-advert/draft" element={<DraftedAdvert />} />
                        <Route path="/your-advert/inactive" element={<InactiveAdvert />} />
                        <Route path="/your-advert/inactive/repost-success/:id" element={<InactiveAdvertSucess />} />
                        <Route path="/your-advert/post-view/:id" element={<BidAndPostView />} />

                        <Route path="/quick-create" element={<QuickCreate />} />
                        <Route path="/create-advert" element={<CreateAdvert />} />

                        <Route path="/objectives" element={<Objectives />} />
                        <Route path="/objectives/add" element={<AddObjectives />} />
                        <Route path="/objectives/your-timeline" element={<YourTimeline />} />

                        <Route path="/performance" element={<Performance />} />

                        <Route path="/financial" element={<Financials />} />
                        <Route path="/financial/monthly-data" element={<MonthlyData />} />
                        <Route path="/financial/invoice" element={<Invoice />} />

                        <Route path="/settings" element={<Settings />} />
                        <Route path="/contact-us" element={<ContactUs />} />
                        <Route path="/account/delete" element={<DeleteAccount />} />
                        <Route path="/help-mode" element={<HelpMode />} />

                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/terms-and-condition" element={<TermsAndCondition />} />
                        <Route path="/cookies-policy" element={<CookiesPolicy />} />

                        <Route path="/keywords" element={<YourKeywords />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <Support />
                </Router>
            </div>
        </Fragment>
    )
}

export default App
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { FaArrowLeftLong } from "react-icons/fa6";
import MainMenu from "../../components/sidemenu/MainMenu";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

const SignUp = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const resendOTP = () => toast.success("A new OTP has been sent to you. Please check your email");

    return (
        <Fragment>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <div className="grid grid-cols-12 h-screen">
                <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                    <MainMenu />
                </div>
                <div className="col-span-11 mt-[1rem]">
                    {(step === 2 || step === 3) &&
                        <div className="px-3">
                            <FaArrowLeftLong
                                onClick={() => setStep(step === 3 ? 2 : 1)}
                                className="text-[20px] cursor-pointer"
                            />
                        </div>
                    }
                    <p className="flex justify-center text-[22px] font-bold">
                        {step === 1 && "Signup And Start Advertising For As Low As 1 - Cent"}
                        {step === 2 && "One - Time - Passcode"}
                    </p>
                    <div className="flex justify-center mt-[1rem]">
                        <div className="w-[35rem] border border-gray-400 bg-[#f4f4f4] rounded-xl p-5 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
                            {/* Signup form */}
                            {step === 1 &&
                                <form className="flex flex-col gap-y-3">
                                    <div className="grid grid-cols-12 gap-3 items-center">
                                        <div className="col-span-5">
                                            <p className="text-[16px]">
                                                Name of your Business:
                                            </p>
                                        </div>
                                        <div className="col-span-7">
                                            <input
                                                type="text"
                                                placeholder="Enter the name of your business"
                                                className="w-full px-2 py-2 border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none"
                                            />
                                        </div>

                                        <div className="col-span-5">
                                            <p className="text-[16px]">
                                                Type of Business:
                                            </p>
                                        </div>
                                        <div className="col-span-7">
                                            <input
                                                type="text"
                                                placeholder="Enter the type of business"
                                                className="w-full px-2 py-2 border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none"
                                            />
                                        </div>

                                        <div className="col-span-5">
                                            <p className="text-[16px]">
                                                Location of Business:
                                            </p>
                                        </div>
                                        <div className="col-span-7">
                                            <input
                                                type="text"
                                                placeholder="Enter the location of your business"
                                                className="w-full px-2 py-2 border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none"
                                            />
                                        </div>

                                        <div className="col-span-5">
                                            <p className="text-[16px]">
                                                Revenue of Business :
                                            </p>
                                        </div>
                                        <div className="col-span-7">
                                            <input
                                                type="text"
                                                placeholder="Enter the revenue of your business"
                                                className="w-full px-2 py-2 border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none"
                                            />
                                        </div>

                                        <div className="col-span-5">
                                            <p className="text-[16px]">
                                                Email :
                                            </p>
                                        </div>
                                        <div className="col-span-7">
                                            <input
                                                type="text"
                                                placeholder="Enter your email"
                                                className="w-full px-2 py-2 border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none"
                                            />
                                        </div>

                                        <div className="col-span-5">
                                            <p className="text-[16px]">
                                                Confirm Email :
                                            </p>
                                        </div>
                                        <div className="col-span-7">
                                            <input
                                                type="text"
                                                placeholder="Confirm your email"
                                                className="w-full px-2 py-2 border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none"
                                            />
                                        </div>

                                        <div className="col-span-5">
                                            <p className="text-[16px]">
                                                Phone Number :
                                            </p>
                                        </div>
                                        <div className="col-span-7">
                                            <input
                                                type="text"
                                                placeholder="Enter your phone number"
                                                className="w-full px-2 py-2 border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none"
                                            />
                                        </div>

                                        <div className="col-span-5">
                                            <p className="text-[16px]">
                                                Password :
                                            </p>
                                        </div>
                                        <div className="col-span-7 relative">
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Enter your password"
                                                className="w-full pl-2 pr-10 py-2 border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none"
                                            />
                                            <div
                                                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <IoEyeOffOutline className="text-[20px]" /> : <IoEyeOutline className="text-[20px]" />}
                                            </div>
                                        </div>

                                        <div className="col-span-5">
                                            <p className="text-[16px]">
                                                Confirm Password :
                                            </p>
                                        </div>
                                        <div className="col-span-7 relative">
                                            <input
                                                type={showConfirmPassword ? "text" : "password"}
                                                placeholder="Confirm your password"
                                                className="w-full pl-2 pr-10 py-2 border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none"
                                            />
                                            <div
                                                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            >
                                                {showConfirmPassword ? <IoEyeOffOutline className="text-[20px]" /> : <IoEyeOutline className="text-[20px]" />}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex justify-between items-center">
                                        <button
                                            onClick={() => navigate("/")}
                                            className="w-[10rem] py-2 text-[14px] bg-[#00AB07] hover:bg-opacity-90 text-[#ffffff] rounded"
                                        >
                                            Login
                                        </button>
                                        <button
                                            onClick={() => setStep(2)}
                                            className="w-[10rem] py-2 text-[14px] bg-[#000000] hover:bg-opacity-90 text-[#ffffff] rounded"
                                        >
                                            Continue to OTP
                                        </button>
                                    </div>
                                </form>
                            }

                            {/* Enter OTP */}
                            {step === 2 &&
                                <form className="flex flex-col gap-y-3">
                                    <p className="text-center px-10">
                                        A one - time - passcode has been sent to your email please check and confirm
                                    </p>
                                    <div className="flex items-center justify-center gap-x-6 w-full">
                                        {[1, 2, 3, 4, 5, 6].map((item) => (
                                            <input
                                                key={item}
                                                type="text"
                                                maxLength={1}
                                                className="w-[3rem] h-[3rem] text-center bg-[#ffffff] border border-gray-400 rounded-lg focus:ring-1 focus:ring-[#00AB07] focus:outline-none"
                                            />
                                        ))}
                                    </div>

                                    <div className="flex justify-between items-center mt-2">
                                        <div
                                            onClick={() => setStep(1)}
                                            className="text-[14px] text-[#00C2FF] hover:underline cursor-pointer"
                                        >
                                            Enter new email
                                        </div>
                                        <div className="text-[14px] text-[#640D5F]">
                                            01:00
                                        </div>
                                    </div>

                                    <div className="flex justify-between items-center mt-2">
                                        <div
                                            onClick={resendOTP}
                                            className="w-[10rem] flex justify-center items-center cursor-pointer py-2 text-[14px] hover:bg-opacity-90 bg-[#000000] text-[#ffffff] rounded"
                                        >
                                            Resend OTP
                                        </div>
                                        <button
                                            onClick={() => setStep(3)}
                                            className="w-[10rem] py-2 text-[14px] bg-[#00AB07] hover:bg-opacity-90 text-[#ffffff] rounded"
                                        >
                                            Continue
                                        </button>
                                    </div>
                                </form>
                            }

                            {/* Thank you message */}
                            {step === 3 &&
                                <div className="flex flex-col gap-y-3">
                                    <div className="flex items-center justify-center">
                                        <p className="w-[80%] text-[16px] text-center">
                                            Congratulations! You can now begin your advertising journey with Kind Viewer.
                                            If you want, you can choose to remain logged in.
                                        </p>
                                    </div>
                                    <div className="flex items-center justify-center gap-x-2">
                                        <input
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={(e) => setIsChecked(e.target.checked)}
                                            className="h-4 w-4 cursor-pointer accent-[#00AB07]"
                                        />
                                        <p className="text-[14px]">
                                            Keep me signed in
                                        </p>
                                    </div>
                                    <div className="flex justify-between items-center mt-5">
                                        <button
                                            onClick={() => setStep(1)}
                                            className="w-[10rem] py-2 text-[14px] bg-[#000000] hover:bg-opacity-90 text-[#ffffff] rounded"
                                        >
                                            Sign out and Close
                                        </button>
                                        <button
                                            onClick={() => isChecked && navigate("/home")}
                                            className="w-[10rem] py-2 flex items-center justify-center text-[14px] bg-[#00AB07] hover:bg-opacity-90 text-[#ffffff] rounded"
                                        >
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

export default SignUp
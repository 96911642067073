import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaMoneyCheckAlt } from "react-icons/fa";
import MainMenu from "../components/sidemenu/MainMenu";
import { useNavigate } from "react-router-dom";
import { GoHomeFill } from "react-icons/go";
import { MdKeyboardArrowRight } from "react-icons/md";
import { currentTeamUp, yourAdvertData } from "../data";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const Account = () => {
    const navigate = useNavigate();
    const [addFunds, setAddFunds] = useState(0);
    const [showPopup, setShowPopup] = useState(null);
    const [accountBalance, setAccountBalance] = useState(1000);
    const defaultFunds = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];
    const [errorMessage, setErrorMessage] = useState("");

    return (
        <div className="grid grid-cols-12 h-screen overflow-hidden">
            <div className="col-span-1 overflow-y-scroll px-3 bg-[#640D5F]">
                <MainMenu />
            </div>
            <div className="col-span-11 overflow-y-scroll p-2">
                {/* breadcrumb */}
                <div className="flex items-center gap-x-[6px] text-[12px] text-[#828282] font-bold">
                    <div
                        onClick={() => navigate("/home")}
                        className="flex items-center gap-x-[4px] cursor-pointer"
                    >
                        <GoHomeFill className="text-[16px]" />
                        <p>Home</p>
                    </div>
                    <MdKeyboardArrowRight className="text-[16px]" />
                    <p className="text-[#00C2FF] cursor-pointer">Account</p>
                </div>

                {/* main content */}
                <div className="mx-2 mt-3">
                    <div className="flex justify-between">
                        <div className="flex items-center space-x-4 mb-6">
                            <img
                                alt="Profile"
                                className="w-32 h-32 rounded-full border-4 border-gray-300"
                                src="https://images.unsplash.com/photo-1608541737042-87a12275d313?q=80&w=2061&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            />
                            <div>
                                <h2 className="text-2xl font-semibold">Nike Store</h2>
                                <p className="text-gray-600">Tier 1</p>
                                <p className="text-gray-600">nike.store@example.com</p>
                                <p className="text-gray-600">Member since: December 2024</p>
                            </div>
                        </div>
                        <div className="relative">
                            <div className="flex items-center gap-x-2">
                                <FaMoneyCheckAlt className="text-[24px] text-black" />
                                <p className="text-[20px] font-bold">
                                    Account Balance: <span className="text-[#00AB07]">${accountBalance}</span>
                                </p>
                            </div>
                            <p className="text-gray-600 text-[12px]">Last updated: 2 hours ago</p>
                            <div className="flex gap-x-2 items-center justify-between mt-2">
                                <p
                                    onClick={() => setShowPopup("add")}
                                    className={`text-[12px] px-3 py-1 ${showPopup === "add" ? "bg-[#00AB07]" : "bg-[#000000]"} text-white hover:bg-[#00AB07] rounded-lg w-full text-center cursor-pointer transition-colors duration-300`}
                                >
                                    Add Funds
                                </p>
                                <p
                                    onClick={() => setShowPopup("withdraw")}
                                    className={`text-[12px] px-3 py-1 ${showPopup === "withdraw" ? "bg-[#00AB07]" : "bg-[#000000]"} text-white hover:bg-[#00AB07] rounded-lg w-full text-center cursor-pointer transition-colors duration-300`}
                                >
                                    Withdraw Funds
                                </p>
                            </div>
                            {(showPopup === "add" || showPopup === "withdraw") &&
                                <div className="mt-2 absolute border border-gray-500 rounded-lg p-2 w-full">
                                    {showPopup === "add" &&
                                        <div className="flex items-center justify-between gap-x-1 overflow-scroll">
                                            {defaultFunds.map((item, index) => (
                                                <p
                                                    key={index}
                                                    onClick={() => setAddFunds(item)}
                                                    className="text-[10px] px-3 py-[4px] text-white bg-gray-900 w-fit rounded-full cursor-pointer font-bold"
                                                >
                                                    ${item}
                                                </p>
                                            ))}
                                        </div>
                                    }
                                    {showPopup === "withdraw" &&
                                        <p className="text-[12px]">Enter custom amount $1 to ${accountBalance}</p>
                                    }
                                    <input
                                        type="number"
                                        value={addFunds}
                                        onChange={(e) => setAddFunds(e.target.value)}
                                        className="w-full py-[4px] px-2 mt-2 border border-gray-500 rounded-lg focus:outline-none "
                                    />
                                    {errorMessage && (
                                        <p className="text-red-500 text-[12px] mt-2">{errorMessage}</p>
                                    )}
                                    <div className="flex gap-x-3 mt-2">
                                        <button
                                            onClick={() => setShowPopup(null)}
                                            className="w-full text-[12px] py-1 bg-[#000000] text-white rounded-lg hover:bg-[#ec4848] transition-colors duration-300"
                                        >
                                            Close
                                        </button>
                                        <button
                                            onClick={() => {
                                                if (showPopup === "withdraw" && addFunds > accountBalance) {
                                                    setErrorMessage("Insufficient funds");
                                                } else {
                                                    setAddFunds(addFunds);
                                                    setAccountBalance(showPopup === "add" ? accountBalance + parseInt(addFunds) : accountBalance - parseInt(addFunds));
                                                    setErrorMessage("");
                                                }
                                            }}
                                            className="w-full text-[12px] py-1 bg-[#000000] text-white rounded-lg hover:bg-[#00AB07] transition-colors duration-300"
                                        >
                                            {showPopup === "add" ? "Add" : "Withdraw"}
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="mb-6">
                        <button
                            onClick={() => navigate("/account/edit")}
                            className="py-1 w-[27%] text-[14px] bg-[#000000] text-white rounded-lg hover:bg-[#00AB07] transition-colors duration-300"
                        >
                            Edit Account
                        </button>
                    </div>
                    <div className="mt-12 flex items-center justify-between">
                        <p className="text-[20px] font-bold mb-2">
                            Active Adverts
                        </p>
                        <p className="text-[12px] text-light-blue-400 cursor-pointer hover:text-light-blue-600 font-bold">
                            View All
                        </p>
                    </div>
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        slidesToSlide={1}
                        responsive={responsive}
                        ssr={true}
                        infinite={true}
                        transitionDuration={500}
                        arrows={false}
                    >
                        {yourAdvertData.map((item) => (
                            <div
                                key={item.id}
                                className="p-4 mx-2 mb-[3.5rem] max-w-sm bg-[#ffffff] border border-gray-300 rounded-md shadow-xl hover:shadow-2xl transition-shadow duration-300"
                            >
                                <div className="overflow-hidden rounded-lg">
                                    <img
                                        src={item.image}
                                        alt={item.name}
                                        className="w-full h-48 cursor-pointer object-cover transition-transform duration-300 hover:scale-110"
                                    />
                                </div>
                                <div className="mt-4">
                                    <h3 className="text-lg font-semibold text-gray-900">
                                        {item.name}
                                    </h3>
                                    <p className="text-sm text-gray-600 mt-2">
                                        <span className="text-gray-700 font-bold">Created</span> - {item.created}
                                    </p>
                                    <p className="text-sm text-gray-600 mt-2">
                                        <span className="text-gray-700 font-bold">Amount Spent</span> - {item.cost}
                                    </p>
                                    <p className="text-sm text-gray-600 mt-2">
                                        <span className="text-gray-700 font-bold">Total Interactions</span> - {item.interactions}
                                    </p>
                                </div>
                                <div className="mt-4">
                                    <button className="w-full py-2 px-4 bg-[#640D5F] text-white rounded-lg hover:bg-opacity-90 transition-colors duration-300">
                                        View Details
                                    </button>
                                </div>
                            </div>
                        ))}
                    </Carousel>

                    <div className="flex items-center justify-between">
                        <p className="text-[20px] font-bold mb-2">
                            Current Team - Ups
                        </p>
                        <p className="text-[12px] text-light-blue-400 cursor-pointer hover:text-light-blue-600 font-bold">
                            View All
                        </p>
                    </div>
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        slidesToSlide={1}
                        responsive={responsive}
                        ssr={true}
                        infinite={true}
                        transitionDuration={500}
                        arrows={false}
                    >
                        {currentTeamUp.map((item) => (
                            <div
                                key={item.id}
                                className="p-4 mx-2 mb-[3.5rem] max-w-sm bg-[#ffffff] border border-gray-300 rounded-md shadow-xl hover:shadow-2xl transition-shadow duration-300"
                            >
                                <div className="overflow-hidden rounded-lg">
                                    <img
                                        src={item.img}
                                        alt={item.name}
                                        className="w-full h-48 object-cover transition-transform duration-300 hover:scale-110"
                                    />
                                </div>
                                <div className="mt-4">
                                    <h3 className="text-lg font-semibold text-gray-900">
                                        {item.name}
                                    </h3>
                                    <p className="text-sm text-gray-600 mt-2">
                                        <span className="text-gray-700 font-bold">Teamed - Up</span> - {item.dateTime}
                                    </p>
                                    <p className="text-sm text-gray-600 mt-2">
                                        <span className="text-gray-700 font-bold">Total Interactions</span> - {item.interactions}
                                    </p>
                                </div>
                                <div className="mt-4">
                                    <button className="w-full py-2 px-4 bg-[#640D5F] text-white rounded-lg hover:bg-opacity-90 transition-colors duration-300">
                                        View Details
                                    </button>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default Account;